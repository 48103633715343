import { render, staticRenderFns } from "./campaignArchive.vue?vue&type=template&id=1aeca805&scoped=true"
import script from "./campaignArchive.vue?vue&type=script&lang=ts"
export * from "./campaignArchive.vue?vue&type=script&lang=ts"
import style0 from "./campaignArchive.vue?vue&type=style&index=0&id=1aeca805&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1aeca805",
  null
  
)

export default component.exports
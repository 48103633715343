
import Vue from 'vue';
import util from '../../../../util';
import { options, CampaignSpendingOption } from './campaignSpendingHelpers';
import _clone from 'lodash.clonedeep';
import { C360Icon } from '@c360/ui';

export default Vue.extend({
  name: 'campaignMarkup',
  data: (): {
    markupText: string;
    edit: boolean;
    error: string | null;
    loading: boolean;
    canEdit: boolean;
    valid: boolean;
    validSubmit: boolean;
    selectionValue: string;
    saveEnabled: boolean;
    campaignSpendingOptions: Array<CampaignSpendingOption> | null;
  } => ({
    markupText: 'disabled',
    edit: false,
    error: null,
    loading: false,
    canEdit: false,
    valid: true,
    validSubmit: true,
    selectionValue: 'Hide',
    saveEnabled: false,
    campaignSpendingOptions: null,
  }),
  props: ['advertiserId', 'campaignIds', 'markupOptions', 'feedSource', 'openMarkup'],
  components: { C360Icon },
  mounted() {
    this.campaignSpendingOptions = _clone(options);
    // no markup for shared pages.
    if (!this.isShared) this.checkMarkupRights();
    this.setValues();
  },
  watch: {
    currentCampaign: {
      handler() {
        this.setValues();
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    currentCampaign() {
      return this.$store.state.customer?.campaignAdPerformance?.CampaignList?.[0];
    },
    selectedOption(): CampaignSpendingOption | null {
      let option = null;
      const found = this.campaignSpendingOptions?.find(
        (option: CampaignSpendingOption) => option.id === this.selectionValue,
      );
      if (found) {
        option = found;
      }
      return option;
    },
    isShared(): boolean {
      return this.$store.state.customer?.sharedSelection?.aid;
    },
    markupValue(): string | number {
      // set markup value based on radio button selection.
      let value = '';
      if (this.selectedOption && this.selectedOption.model) {
        value = this.selectedOption.id === 'Hide' ? 'Hide' : this.selectedOption.model;
      }
      return value;
    },
    isMobile(): boolean {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    customWarning(): string {
      let warning = '';
      if (this.selectedOption) {
        warning = this.selectedOption.customWarning;
      }
      return warning;
    },
  },
  methods: {
    setCampaignMarkup(): void {
      // submit markup value, then reload data.
      const post = {
        id: this.advertiserId,
        campaignId: this.campaignIds.join(','),
        type: this.selectionValue,
        value: this.markupValue,
        campaignSource: this.feedSource,
      };

      if (this.$refs.markup.validate()) {
        try {
          this.edit = false;
          this.loading = true;
          // console.log({ post });
          this.$store.dispatch('setCampaignSpendType', { data: post }).then(async data => {
            if (data.error) {
              // eslint-disable-next-line no-console
              console.error('setCampaignSpendType error', data);
              this.loading = false;
            } else {
              this.edit = false;
              this.loading = false;

              // reload data via loadAllCampaigns on home.vue.
              await this.$store.dispatch('reloadCampaigns', true);
              this.$emit('close');

              // reload tactic summary data if Youtube
              const tactic = util.dataKeyBySectionIdMap(
                this.$route.query.tab || this.$store.state.customer?.sharedSelection?.tab,
              );
              if (tactic === 'GOOGLEVIDEO') {
                // pass token if shared.
                const token = this.$store.state.customer?.sharedSelection?.etoken
                  ? this.$store.state.customer?.sharedSelection?.etoken
                  : null;
                this.$store.dispatch('getTacticSummary', {
                  tactic,
                  advertiserId: this.$route.query.id || this.$store.state.customer?.sharedSelection?.aid,
                  token,
                  force: true,
                });
              }
            }
          });
        } catch (exp) {
          // eslint-disable-next-line no-console
          console.error(exp.message);
        }
      } else {
        this.validSubmit = false;
      }
    },
    checkMarkupRights(): void {
      // check for edit rights
      const roles = ['CAMPAIGN_MARKUP_EDIT'];
      this.canEdit = util.hasRight(this.$store.state.customer.user, roles);
    },
    setValues(): void {
      // set the markup values from store
      if (this.currentCampaign) {
        const rate =
          this.currentCampaign?.SpendType === 'CPD'
            ? this.currentCampaign?.CostPerDeployment
            : this.currentCampaign?.SpendRate;
        const spendType = this.currentCampaign?.SpendType;
        // set the correct values when the component is mounted.
        this.selectionValue = spendType;

        // make sure number comes back with 2 decimals, real fix needs to happen on backend
        const fixedRate = parseFloat(rate.replace('$', '')).toFixed(2);

        if (this.selectedOption) {
          const selectedRate = spendType === 'Markup' ? rate : fixedRate;
          this.selectedOption.model = selectedRate;
          this.markupText = this.selectedOption.textRule.replace('{{rate}}', selectedRate);
        }
      }
    },
    clearValues(): void {
      // clear values based on selection.
      this.$refs.markup.resetValidation();
      this.validSubmit = true;
      this.saveEnabled = true;

      if (this.selectedOption) {
        this.selectedOption.model = null;
        this.campaignSpendingOptions.forEach((option: CampaignSpendingOption) => {
          if (option.id === this.selectionValue) {
            // re-add rules to selected option for form validation
            const original = _clone(options).find(o => o.id === this.selectionValue);
            if (original) {
              option.rules = [...original.rules];
            }
          } else {
            // no rules for unselected options so not to make form invalid
            option.rules = [];
          }
        });
      }
    },
    isAvailable(value: string): boolean {
      const options = [...this.markupOptions, 'Hide'];
      return options?.includes(value);
    },
    closeDialog(): void {
      this.setValues();
      this.$emit('close');
    },
  },
});


import Vue from 'vue';
import { C360Icon } from '@c360/ui';

export default Vue.extend({
  inheritAttrs: false,
  name: 'campaignArchive',
  components: { C360Icon },
  props: ['selectedCampaigns', 'selectedArchiveType', 'isOpenDialog'],
  data: (): {
    currentOrderSort: number;
    selectedYear: number;
  } => ({
    currentOrderSort: 0,
    selectedYear: new Date().getFullYear(),
  }),
  computed: {
    isArchived(): boolean {
      return this?.selectedCampaigns[0]?.Archived;
    },
    yearsFrom2021ToCurrent(): Array<number> {
      const currentYear = new Date().getFullYear();
      const years: Array<number> = [];
      for (let year = 2021; year <= currentYear; year++) {
        years.push(year);
      }
      return years;
    },
  },
  methods: {
    closeDialog(): void {
      this.$emit('close');
    },
    async archiveCampaign(): Promise<void> {
      const advertiserId = this.$route.query?.id;
      this.selectedCampaigns.forEach(async item => {
        const payload = {
          advertiserId,
          callback: this.reloadCampaign,
        };

        const campaign = {
          id: item?.id,
          source: item?.FeedSource,
        };
        const type = item?.CampaignType;
        const year = this.selectedYear;
        switch (this.selectedArchiveType) {
          case 0:
            payload['campaign'] = campaign;
            break;
          case 1:
            payload['type'] = type;
            break;
          case 2:
            payload['year'] = year;
            break;
        }

        await this.$store.dispatch('archiveCampaign', payload);
      });
      this.closeDialog();
    },
    async reloadCampaign(): Promise<void> {
      const advertiserId = this.$route.query?.id;
      await this.$store.dispatch('getCampaigns', {
        advertiserId,
        daterange: 'alltime',
        skipCached: true,
      });
    },
    async restoreCampaign(): Promise<void> {
      const advertiserId = this.$route.query?.id;
      this.selectedCampaigns.forEach(async campaign => {
        try {
          const payload = {
            advertiserId,
            campaignId: campaign?.id,
            onSuccessCallback: this.reloadCampaign,
          };
          const res = await this.$store.dispatch('linearAdmin/unhideCampaign', payload);
          if (res.error) {
            this.$store.dispatch('showError', {
              message: res.error,
              errorType: 'error',
            });
            return;
          }
          this.$store.dispatch('showError', {
            message: 'Campaign was successfully restore',
            errorType: 'success',
          });
        } catch (error) {
          this.$store.dispatch('showError', {
            message: 'Failed to restore campaign. Please try again.',
            errorType: 'error',
          });
        }
      });
    },
  },
});


import Vue from 'vue';
import utils from '../../../../util';
import _clone from 'lodash.clonedeep';
import analytics from '../../../../mixins/analytics';
import { C360Icon } from '@c360/ui';

interface Module {
  cid: string;
  title: string;
  name: string;
  id: string;
  chartTypes?: Array<ModuleOption>;
  selectedChartType?: ModuleOption;
  overrides?: {
    id: string;
    onlySummary?: boolean;
    onlyMap?: boolean;
    disabled?: boolean;
  };
}

interface ModuleOption {
  cid: string;
  title: string;
  name: string;
  id: string;
  dataSource: string;
  chartTypes?: Array<ModuleOption>;
  chartTypeTitle?: string;
}

export default Vue.extend({
  name: 'AddModuleC360',
  props: ['smallerScreen'],
  components: { C360Icon },
  mixins: [analytics],
  data: (): {
    selection: {
      title: string;
      breakpoints: string[] | null;
      module: Module | null;
      selectedChartType: Module | null;
    };
    disableAdd: boolean;
    selectedModule: number | null;
    showMenu: boolean;
    windowHeight: number;
  } => ({
    selection: {
      title: '',
      breakpoints: null,
      module: null,
      selectedChartType: null,
    },
    disableAdd: true,
    selectedModule: null,
    showMenu: false,
    windowHeight: 0,
  }),
  mounted(): void {
    this.selection.breakpoints = this.breakpointOptions[0];
    window.addEventListener('resize', this.onResize);
    this.onResize();
  },
  methods: {
    add(): void {
      let selectedModule = this.selection.module;
      if (this.selection.selectedChartType) {
        selectedModule = this.selection.module.chartTypes.find(c => c.id === this.selection.selectedChartType);
      }

      // create unique id in case of duplicate charts
      // id: cid_general number on page_number of this type
      const existingModules = _clone(this.$store.state.layoutEditor.currCustomizations);
      const numberOfModules = existingModules.length;
      const numberOfThisType = existingModules.filter(item => item.cid === selectedModule.cid).length;

      let id = `${selectedModule.cid}_${numberOfModules + 1}_${numberOfThisType + 1}`;
      if (this.isMap(selectedModule.name)) {
        id = selectedModule.id;
      }
      // update breakpoints
      const breakpoints = Array.isArray(this.selection.breakpoints)
        ? this.selection.breakpoints
        : this.selection.breakpoints.value;

      const newModule = {
        cid: selectedModule.cid,
        id,
        overrides: {
          id,
          title: this.selection.title,
          breakpoints,
          disabled: false, // in case disabled by default
          ...selectedModule.overrides,
        },
      };

      // DASH-5460: if layout has advLevelModules, add new module not in the end but before the section separator
      const customizations = _clone(this.$store.state.layoutEditor.currCustomizations);

      const hasAdvLevelModules = this.$store.state.layoutEditor.currCustomizations.findIndex(
        c => c.cid === 'broadcastInnovidXPSectionHeader',
      );
      if (hasAdvLevelModules) {
        customizations.splice(hasAdvLevelModules, 0, newModule);
      } else {
        customizations.push(newModule);
      }
      this.$store.dispatch('layoutEditor/setCurrCustomizations', customizations);
      this.$store.dispatch('layoutEditor/setEnableSave', true);

      // add to dynamic layout so it shows up in real time
      const defaultComponents = utils.getLayoutComponents(this, 'default');
      const found = _clone(defaultComponents.find(comp => comp.cid === newModule.cid));
      if (found) {
        // 'fill out' the module with pre-exisiting default componentConfig
        found['overrides'] = newModule.overrides;
        Object.keys(found.overrides).forEach(key => {
          found[key] = found.overrides[key];
        });
        this.$store.dispatch('addNewModule', { newModule: found, makeFirst: false, hasAdvLevel: hasAdvLevelModules });
      }
      this.clear();

      setTimeout(() => {
        if (hasAdvLevelModules !== -1) {
          window.scrollTo({ top: document.body.scrollHeight - 2000, behavior: 'smooth' });
        } else {
          window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
        }
      }, 700);
      this.analyticTrack(this.trackValue.EVENT_CONFIGURABILITY, this.trackValue.ADD_MODULE);
    },
    selectModule(index: number, option: Module): void {
      // disbale selection if already has map
      // note: doing this way bc disabled btn doesnt allow tooltip hover
      if (this.disableOption(option)) {
        return;
      }

      this.selectedModule = index;
      this.selection.module = this.moduleOptions[index];
      this.selection.title = this.selection.module.title;

      // force width to be full if map + summary
      if (this.forcedFullWidth) {
        this.selection.breakpoints = this.breakpointOptions[1];
      }

      // keep add disabled if still choice to choose chart type
      if (!this.hasMultipleChartTypes(this.selection.module)) {
        this.disableAdd = false;
      }
    },
    isMap(chart: string) {
      return ['dmaZipMap', 'stationMap', 'genericMap', 'geofenceMap'].includes(chart);
    },
    getChartType(chart: string): { icon: string; title?: string; c360Icon: string } {
      let obj: { icon: string; title?: string; c360Icon: string } = { icon: '', title: '', c360Icon: '' };
      const other = ['campaignOverview', 'sideSummary', 'textCampaignSummary', 'campaignStats', 'siteImpactTable'];
      if (this.isMap(chart)) {
        obj = { icon: 'public', c360Icon: 'Globe' };
      } else if (chart === 'genericTable' || chart === 'tableList') {
        obj = { icon: 'table_chart', title: 'Table', c360Icon: 'Table' };
      } else if (chart === 'progressBarTable') {
        obj = { icon: 'table_chart', title: 'Progress Bar Table', c360Icon: 'Table' };
      } else if (chart === 'genericBarChart') {
        obj = { icon: 'equalizer', title: 'Bar Chart', c360Icon: 'Chart_Bar_Vertical_01' };
      } else if (chart === 'lineAndBarChart') {
        obj = { icon: 'equalizer', title: 'Line and Bar Chart', c360Icon: 'Chart_Bar_Vertical_01' };
      } else if (chart === 'genericLineBarChartNew') {
        obj = { icon: 'equalizer', title: 'Line and Bar Chart New', c360Icon: 'Chart_Bar_Vertical_01' };
      } else if (chart === 'genericLineChart') {
        obj = { icon: 'show_chart', title: 'Line Chart', c360Icon: 'Chart_Line' };
      } else if (chart === 'genericPie') {
        obj = { icon: 'donut_large', title: 'Pie Chart', c360Icon: 'Chart_Pie' };
      } else if (chart === 'slingNetworkLogosList') {
        obj = { icon: 'collections', c360Icon: 'Compass' };
      } else if (chart === 'targetingList') {
        obj = { icon: 'my_location', c360Icon: 'Map_Pin' };
      } else if (chart === 'benchmarksChart') {
        obj = { icon: 'show_chart', title: 'Line Chart', c360Icon: 'Chart_Line' };
      } else if (other.includes(chart)) {
        obj = { icon: 'article', c360Icon: 'Globe' };
      }

      return obj;
    },
    clear(): void {
      this.selection = {
        title: '',
        breakpoints: this.breakpointOptions[0],
        module: null,
      };
      this.selectedModule = null;
      this.disableAdd = true;
      this.showMenu = false;
    },
    disableOption(option) {
      if (option.name === 'siteImpactTable' && this.alreadyHasSITable) return true;
      if (option.cid.includes('CampaignTargeting') && this.alreadyHasCampaignTargeting) return true;
      if (!this.isMap(option.name) || !this.alreadyHasMapOrSummary) return false;

      const isMapSummary = option.title === 'Map and Campaign Summary';
      const isMapOnly = option.title === 'Map Only';
      const isSummaryOnly = option.title === 'Campaign Summary Only';

      return (
        isMapSummary ||
        ((isMapOnly || isSummaryOnly) && !this.hasMapSummaryCombo && !this.hasMapOnly && !this.hasOnlySummary) ||
        ((isMapOnly || isSummaryOnly) && this.hasMapOnly && this.hasOnlySummary) ||
        (isSummaryOnly && this.hasOnlySummary && !this.hasMapOnly)
      );
    },
    disabledText(option: Module): string {
      let text = '';
      if (option?.overrides?.onlySummary) {
        text = 'No duplicate campaign summaries';
      } else if (this.isMap(option.name)) {
        text = 'No duplicate maps';
      } else if (option.name === 'siteImpactTable') {
        text = 'No duplicate Links Breakdown table';
      } else if (option.cid.includes('CampaignTargeting')) {
        text = 'No duplicate Campaign Targeting List';
      }
      return text;
    },
    hasMultipleChartTypes(modOption: Module) {
      if (modOption.title === 'Benchmarks') {
        return false;
      }
      return Array.isArray(modOption.chartTypes) && modOption.chartTypes.length > 1;
    },
    onResize(): void {
      this.windowHeight = window.innerHeight;
    },
  },
  computed: {
    breakpointOptions(): object[] {
      return utils.breakpointOptions;
    },
    moduleOptions(): Array<ModuleOption> {
      const components = utils.getLayoutComponents(this, 'default');
      const options: Array<ModuleOption> = [];
      const usedDataSources: string[] = [];

      components.forEach(comp => {
        const { cid, name, id, title, dataSource } = comp;

        if (this.isMap(name)) {
          options.push({ cid, title: 'Map and Campaign Summary', name, id, dataSource });

          if (!this.hasMapSummaryCombo || this.hasOnlyMap) {
            // two more options for map only and summary only
            const onlyMap = {
              cid,
              title: 'Map Only',
              name,
              id: `${id}OnlyMap`,
              dataSource,
              overrides: { onlyMap: true, id: `${id}OnlyMap` },
            };
            const onlySummary = {
              cid,
              title: 'Campaign Summary Only',
              name,
              id: `${id}OnlySummary`,
              dataSource,
              overrides: { onlySummary: true, id: `${id}OnlySummary` },
            };
            options.push(onlyMap);
            options.push(onlySummary);
            return;
          }
        }
        // DASH-3562: Do not merge Line Charts and FTA Line Charts for GT Tactic
        if (
          cid === 'gtdisplayFTAPerformanceByDayOfWeekLineChart' ||
          cid === 'gtdisplayDailyPerformanceByDayOfWeekLineChart' ||
          cid === 'gtvideoStoreVisitsPerformanceLineChart' ||
          cid === 'gtvideoDailyPerformanceByDayOfWeekLineChart' ||
          cid === 'audioCampaignTargeting'
        ) {
          options.push({ cid, title, name, id, dataSource });
          return;
        }

        // DASH-5455: Do not allow adding performance by creative chart if all selected campaigns are CHPP on facebook
        const hasOnlyCHPPCampaigns =
          this.$store.state.customer?.currentNavTab === 'social' &&
          this.$store.state.customer?.campaignAdPerformance?.CampaignList?.every(item =>
            item?.FriendlyName.toLowerCase().includes('chpp'),
          );

        if (hasOnlyCHPPCampaigns && cid.toLowerCase().includes('performancebycreative')) {
          return;
        }

        // DASH-5460: hide innovidxp adv level modules for broadcast in add module menu
        if (
          this.$store.state.customer?.currentNavTab === 'broadcast' &&
          cid.toLowerCase().includes('broadcastinnovidxp')
        )
          return;

        const currentFeedSource = utils.feedSources(this);
        if (comp?.feedSourceToExclude?.length) {
          if (comp.feedSourceToExclude?.some(item => currentFeedSource.includes(item))) return;
        }
        if (comp?.feedSource?.length) {
          if (!comp.feedSource?.some(item => currentFeedSource.includes(item))) return;
        }
        if (!usedDataSources.includes(comp.dataSource)) {
          // add to options and create chartTypes array
          usedDataSources.push(comp.dataSource);
          const modOption = { cid, title, name, id, dataSource, chartTypeTitle: this.getChartType(name).title };
          options.push({ ...modOption, chartTypes: [modOption] });
          return;
        }
        const found = options.find(o => o.dataSource === comp.dataSource);
        if (found?.chartTypes) {
          found.chartTypes.push({
            cid,
            title,
            name,
            id,
            dataSource,
            chartTypeTitle: this.getChartType(name).title,
          });
        }
      });
      return options;
    },
    disableTitle(): boolean {
      return this.isMap(this.selection?.module?.name);
    },
    forcedFullWidth(): boolean {
      return (
        this.hasMapSummaryCombo ||
        this.selection?.module?.name === 'siteImpactTable' ||
        this.selection?.module?.id === 'ottRetargetingCampaignsTableList'
      );
    },
    hasMapSummaryCombo(): boolean {
      const isMap = this.isMap(this.selection.module?.name);
      const notSeparate = !this.selection.module?.overrides?.onlyMap && !this.selection.module?.overrides?.onlySummary;
      return isMap && notSeparate;
    },
    alreadyHasMapOrSummary(): boolean {
      const dynamicComponents = utils.getLayoutComponents(this);
      const found = dynamicComponents.find(comp => {
        return this.isMap(comp.name);
      });
      return !!found;
    },
    alreadyHasSITable(): boolean {
      const dynamicComponents = utils.getLayoutComponents(this);
      const found = dynamicComponents.find(comp => {
        return comp.name === 'siteImpactTable';
      });
      return !!found;
    },
    alreadyHasCampaignTargeting(): boolean {
      const dynamicComponents = utils.getLayoutComponents(this);
      const found = dynamicComponents.find(comp => {
        return comp.cid.includes('CampaignTargeting');
      });
      return !!found;
    },
    hasOnlyMap(): boolean {
      const dynamicComponents = utils.getLayoutComponents(this);
      const found = dynamicComponents.find(comp => this.isMap(comp.name));
      return found?.onlyMap;
    },
    menuMaxHeight(): number {
      return this.windowHeight - 100;
    },
    hasMapOnly(): boolean {
      const dynamicComponents = utils.getLayoutComponents(this);
      const found = dynamicComponents.find(comp => comp.title === 'Map Only');
      return !!found;
    },
    hasOnlySummary(): boolean {
      const dynamicComponents = utils.getLayoutComponents(this);
      const found = dynamicComponents.find(comp => comp.title === 'Campaign Summary Only');
      return !!found;
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
});

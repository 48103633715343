
import Vue from 'vue';
import {
  CampaignNoteParams,
  CampaignNote,
  GetCampaignNotesResponse,
  CampaignNoteUpdateParams,
} from '../../../../../../../apis/dashboardGraphqlApi/dataSources/dataAPI/types/campaigns';
import { format } from 'date-fns-tz';
import utils from '../../../../util';
import analytics from '../../../../mixins/analytics';
import { C360Icon } from '@c360/ui';

export default Vue.extend({
  name: 'CampaignNotes',
  mixins: [analytics],
  data: (): {
    allNotes: Array<CampaignNote> | null; // type this
    editMode: boolean;
    editNoteBody: string | null;
    editNoteTitle: string | null;
    loading: boolean;
    enableSave: boolean;
    isAdd: boolean;
    selectedNoteId: string | null;
    deleteConfirmation: boolean;
    titleRequired: boolean;
    listMaxHeight: { 'max-height': string };
  } => ({
    allNotes: null,
    editMode: false,
    editNoteBody: null,
    editNoteTitle: null,
    loading: true,
    enableSave: false,
    isAdd: false,
    selectedNoteId: null,
    deleteConfirmation: false,
    titleRequired: false,
    listMaxHeight: { 'max-height': 'auto' },
  }),
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
  },
  components: { C360Icon },
  mounted(): void {
    this.onResize();
    this.getNotes();
    window.addEventListener('resize', this.onResize);
  },
  methods: {
    getNotes(): void {
      this.loading = true;
      this.$store.dispatch('getCampaignNotes', this.basicParamInfo).then((resp: GetCampaignNotesResponse) => {
        this.allNotes = resp?.notes;
        this.loading = false;
        this.analyticTrack(this.trackValue.TRACK_CAMP_NOTES, this.trackValue.CAMP_NOTES_VIEWED);
      });
    },
    addNote(): void {
      if (!this.editNoteTitle) {
        this.titleRequired = true;
      } else {
        // make line breaks html friendly
        const body = this.editNoteBody.replaceAll(/\n/g, '<br/>');
        const params: CampaignNoteParams = {
          ...this.basicParamInfo,
          title: this.editNoteTitle,
          body,
        };
        this.loading = true;
        this.$store.dispatch('addCampaignNote', params).then(() => {
          this.getNotes();
          this.analyticTrack(this.trackValue.TRACK_CAMP_NOTES, this.trackValue.CAMP_NOTE_CREATED);
        });
        this.returnToNoteList();
      }
    },
    updateNote(): void {
      if (!this.editNoteTitle) {
        this.titleRequired = true;
      } else {
        // make line breaks html friendly
        const body = this.editNoteBody.replaceAll(/\n/g, '<br/>');

        const params: CampaignNoteUpdateParams = {
          ...this.basicParamInfo,
          noteId: this.selectedNoteId,
          title: this.editNoteTitle,
          body,
        };
        this.loading = true;
        this.$store.dispatch('updateCampaignNote', params).then(() => {
          this.getNotes();
          this.analyticTrack(this.trackValue.TRACK_CAMP_NOTES, this.trackValue.CAMP_NOTE_EDITED);
        });
        this.returnToNoteList();
      }
    },
    deleteNote(): void {
      const found = this.allNotes.find((note: CampaignNote) => note.noteId === this.selectedNoteId);
      if (found) {
        const params: CampaignNoteUpdateParams = {
          ...this.basicParamInfo,
          noteId: this.selectedNoteId,
        };
        this.$store.dispatch('deleteCampaignNote', params).then(() => {
          this.getNotes();
          this.analyticTrack(this.trackValue.TRACK_CAMP_NOTES, this.trackValue.CAMP_NOTE_DELETED);
        });
      }
    },
    openAddNote(): void {
      this.editMode = true;
      this.isAdd = true;
    },
    openEditNote(id: string): void {
      const found = this.allNotes.find((note: CampaignNote) => note.noteId === id);
      if (found) {
        this.editMode = true;
        const body = found.body.replaceAll('<br/>', '\n'); // revert line breaks for editing
        this.editNoteBody = body;
        this.editNoteTitle = found.title;
        this.selectedNoteId = id;
      }
    },
    openDeleteConfirmation(id: string): void {
      this.deleteConfirmation = true;
      this.selectedNoteId = id;
    },
    returnToNoteList(): void {
      this.editMode = false;
      this.isAdd = false;
      this.editNoteBody = '';
      this.editNoteTitle = '';
      this.selectedNoteId = null;
      this.deleteConfirmation = false;
      this.enableSave = false;
      this.titleRequired = false;
    },
    exitDialog(): void {
      this.$emit('close');
      this.allNotes = null;
      this.loading = true;
      this.returnToNoteList();
    },
    getNoteHeight(id: string): { height: string } {
      const height = `${this.$refs[`note-${id}`][0].clientHeight - 16}px`;
      return { height };
    },
    canEditNote(createdBy): boolean {
      const user = this.$store.state?.customer?.user;
      const currentUserHasRight = createdBy === this.basicParamInfo.email && this.hasEditRights;
      // hasRight additionally checks for SU permissions
      const userAdminOrSu = utils.hasRight(user, ['AGENCY_ADMIN']) || user?.is_superuser;

      return currentUserHasRight || userAdminOrSu;
    },
    formattedDate(date: string): string {
      let formatted = '';
      const parsed = new Date(`${date} GMT`);
      const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      if (parsed) {
        formatted = `${days[parsed.getDay()]},
          ${months[parsed.getMonth()]}
          ${parsed.getDate()}
          ${format(parsed, 'hh:mm a')} PST`;
      }
      return formatted;
    },
    onResize(): void {
      this.listMaxHeight = { 'max-height': `${window.innerHeight - 150}px` };
    },
  },
  computed: {
    basicParamInfo(): CampaignNoteParams {
      const { user, selection } = this.$store.state.customer;
      const { selectedCampaigns } = this.$store.state.filters;
      const params: CampaignNoteParams = {
        advertiserId: selection.advertiserId,
        campaignId: selectedCampaigns[0].id,
        email: user?.email,
      };
      return params;
    },
    hasEditRights(): boolean {
      return utils.hasRight(this.$store.state?.customer?.user, ['CAMPAIGN_NOTES_EDIT']);
    },
    error(): string | null {
      return this.$store.state?.customer?.noteError || null;
    },
  },
  beforeDestroy(): void {
    window.removeEventListener('resize', this.onResize);
  },
});
